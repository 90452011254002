<template>
  <div>
    <el-dialog :visible.sync="showIndicationDetail" :before-close="onHideModal"
      :width="type === 1 && tableData.length && hasResult ? '100%' : '75%'">
      <div v-loading="isLoading">
        <div class="relative text-black" v-if="indication">
          <div slot="title"
            class="flex justify-between items-center border-b border-l-0 border-t-0 border-r-0 border-solid border-gray-200 mb-2 pb-2">
            <div class="text-lg font-bold">{{ indication.name }}</div>
            <!-- <div class="text-sm italic">{{ indication.code }}</div> -->
          </div>
          <div class="mb-2">
            <div class="row">
              <div class="col-4">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("form.title.name") }}:</div>
                  <div class="ml-2 fs-15">{{ personData.name }}</div>
                </div>
              </div>
              <div class="col-4">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("form.title.bday") }}:</div>
                  <div class="ml-2 fs-15" v-if="personData.birthday">
                    {{
                      `${appUtils.formatDate(
                        personData.birthday
                      )} (${mdtUtils.getAges(new Date(personData.birthday))})`
                    }}
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("form.title.gender") }}:</div>
                  <div class="ml-2 fs-15" v-if="personData.gender">
                    {{ mdtUtils.getGender(personData.gender, $lang) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-8">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">
                    {{ $t("form.title.address") }}:
                  </div>
                  <div class="ml-2 fs-15" v-if="personData">
                    {{ getAddress(personData) }}
                  </div>
                </div>
              </div>
              <div class="col-4">
                <div class="flex mb-2">
                  <div class="fw-500 fs-15">{{ $t("form.title.phone") }}:</div>
                  <div class="ml-2 fs-15" v-if="personData.phone">
                    {{ personData.phone }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Thông tin cơ bản của xét nghiệm -->
            <div v-if="type === 2 && tableData.length">
              <div class="row">
                <div class="col-md-6 mb-2">
                  <label class="fw-500 fs-15" style="margin-right: 4px">Kỹ thuật viên 1:</label>
                  <span class="fs-16 fw-700 view-value">
                    {{ tableData[0].meta_data.first_tech_employee_name }}
                  </span>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="fw-500 fs-15" style="margin-right: 4px">Kỹ thuật viên 2:</label>
                  <span class="fs-16 view-value">{{
                    tableData[0].meta_data.second_tech_employee_name
                    }}</span>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-2">
                  <label class="fw-500 fs-15" style="margin-right: 4px">Bác sĩ đọc kết quả:</label>
                  <span class="fs-16 fw-700 view-value">
                    {{ tableData[0].meta_data.summary_employee_name }}
                  </span>
                </div>
                <div class="col-md-6 mb-2">
                  <label class="fw-500 fs-15" style="margin-right: 4px">Ngày đọc kết quả:</label>
                  <span class="fs-16 fw-700 view-value">
                    {{
                      tableData[0].meta_data.completed_date_time &&
                      appUtils.formatDateTime(
                        tableData[0].meta_data.completed_date_time
                      )
                    }}
                  </span>
                </div>
              </div>
            </div>
            <!-- End of Thông tin cơ bản của xét nghiệm -->
          </div>

          <div class="row">
            <div :class="type === 1 && tableData.length && hasResult ? 'col-5' : 'col-12'
              ">
              <div class="sticky" style="top: 40px">
                <div class="mb-3 hidden">
                  <h4 class="fs-15">{{ $t("Các chỉ số định lượng") }}</h4>
                  <div class="hodo-ckeditor__big">
                    <EditorInput id="quantitativeIndex-indication" :prop_text="form.quantitativeIndex" @onChangeContent="(content) => (form.quantitativeIndex = content)
                      " />
                  </div>
                </div>
                <div class="mb-3">
                  <h4 class="fs-15">{{ $t("Các tệp kết quả") }}</h4>
                  <div class="mb-2">
                    <input type="file" @change="onSelectedFile($event)" class="hidden" hidden ref="inputFileIndication"
                      multiple />
                    <input type="file" @change="onSelectedFileDicom($event)" class="hidden" hidden accept=".zip, .rar"
                      ref="inputFileIndicationDicom" multiple />
                    <div class="flex flex-col">
                      <div v-if="form.files.length" class="flex flex-wrap -mx-2">
                        <div v-for="(file, index) in form.files" :key="file.id || index"
                          :class="file.type === 4 ? 'w-full' : ''">
                          <div v-if="file.type === 4" class="flex flex-col gap-2 mb-3 px-2">
                            <div class="flex items-center flex-1">
                              <div
                                class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md w-16 h-16">
                                <span v-if="modifiable" @click="
                                  onDeleteIndicationAttachWithType({
                                    id: file.id,
                                    index,
                                    type: 1,
                                  })
                                  "
                                  class="flex absolute z-20 top-0 right-0 -mt-2 -mr-2 w-3 h-3 rounded-full bg-gray-300 hover:bg-red-500 hover:text-white transition-all duration-200">
                                  <i class="el-icon-close m-auto" />
                                </span>
                                <span @click="openWindow(file.file_name)" class="flex w-full text-zero overflow-hidden">
                                  <img v-if="isImage(file)"
                                    class="w-full h-full object-cover transform origin-center rounded-md" :src="file.file_name ||
                                      onHandleCreateObjectURL(file)
                                      " :alt="file.name" />
                                  <span v-else class="m-auto text-xl font-bold uppercase whitespace-nowrap">{{
                                    getExtensionOfFile(file.name) }}</span>
                                </span>
                              </div>
                              <div class="ml-2 w-96" v-if="modifiable">
                                <el-input placeholder="Nhập tên file" v-model="file.name" clearable></el-input>
                              </div>
                            </div>
                            <video width="400" controls>
                              <source :src="file.resultUrl" type="video/mp4" />
                            </video>
                          </div>
                          <div v-else-if="!file.pac_id" class="flex flex-col gap-2 mb-3 px-2">
                            <div class="flex items-center">
                              <div
                                class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md w-16 h-16">
                                <span v-if="modifiable" @click="
                                  onDeleteIndicationAttachWithType({
                                    id: file.id,
                                    index,
                                    type: 1,
                                  })
                                  "
                                  class="flex absolute z-20 top-0 right-0 -mt-2 -mr-2 w-3 h-3 rounded-full bg-gray-300 hover:bg-red-500 hover:text-white transition-all duration-200">
                                  <i class="el-icon-close m-auto" />
                                </span>
                                <span @click="openWindow(file.file_name)" class="flex w-full text-zero overflow-hidden">
                                  <img v-if="isImage(file)"
                                    class="w-full h-full object-cover transform origin-center rounded-md" :src="file.file_name ||
                                      onHandleCreateObjectURL(file)
                                      " :alt="file.name" />
                                  <span v-else class="m-auto text-xl font-bold uppercase whitespace-nowrap">{{
                                    getExtensionOfFile(file.name) }}</span>
                                </span>
                              </div>
                              <div class="ml-2" v-if="modifiable">
                                <el-input placeholder="Nhập tên file" v-model="file.name" clearable></el-input>
                              </div>
                            </div>
                          </div>
                          <div v-else class="flex items-center mb-3 px-2">
                            <div
                              class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md w-16 h-16">
                              <span v-if="modifiable" @click="
                                onDeleteIndicationAttachWithType({
                                  id: file.id,
                                  index,
                                  type: 1,
                                })
                                "
                                class="flex absolute z-20 top-0 right-0 -mt-2 -mr-2 w-3 h-3 rounded-full bg-gray-300 hover:bg-red-500 hover:text-white transition-all duration-200">
                                <i class="el-icon-close m-auto" />
                              </span>
                              <span @click="openPac(file.pacs)" class="flex w-full text-zero overflow-hidden">
                                <div class="selected-file-icon" style="
                                    background-image: url('/assets/images/icon/pac-icon.png');
                                  "></div>
                              </span>
                            </div>

                            <div :set="(pacInfo = handleGetPacInfo(file))" class="ml-2" v-if="modifiable">
                              <el-input placeholder="Nhập tên file" v-model="file.name" clearable></el-input>
                              <el-tag size="mini" :type="pacInfo.type" effect="dark" class="mt-1">{{ pacInfo.label
                                }}</el-tag>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div v-if="form.links.length" class="flex flex-wrap -mx-2">
                        <div class="flex items-center px-2" :class="modifiable ? 'w-1/4 mb-3' : 'mb-2 text-lg'"
                          v-for="(link, index) in form.links" :key="link.id || index">
                          <template v-if="modifiable">
                            <el-form ref="formLinks" class="w-full">
                              <el-form-item class="mb-0" :label="$t('URL')" required :rules="[
                                {
                                  required: true,
                                  message: 'Trường này không được để trống',
                                  trigger: 'blur',
                                },
                              ]">
                                <el-input placeholder="Nhập link" v-model="link.url" clearable></el-input>
                              </el-form-item>
                              <el-form-item class="mb-0" :label="$t('Tên link')">
                                <el-input placeholder="Nhập tên link" v-model="link.name" clearable></el-input>
                              </el-form-item>
                            </el-form>
                            <div v-if="modifiable" @click="
                              onDeleteIndicationAttachWithType({
                                id: link.id,
                                index,
                                type: 2,
                              })
                              " role="button"
                              class="flex h-full bg-gray-100 hover:bg-red-100 hover:text-red-500 rounded-md ml-2 px-2 transition-all duration-200">
                              <div class="flex m-auto">
                                <i class="el-icon-close m-auto" />
                              </div>
                            </div>
                          </template>
                          <template v-else>
                            <i class="el-icon-link mr-1" />
                            <span class="text-blue-800 hover:text-blue-600 transition-all duration-200 cursor-pointer"
                              @click="openFile(link)">{{ link.name || link.url }}</span>
                          </template>
                        </div>
                      </div>

                      <div v-if="modifiable" class="flex -mx-2">
                        <div class="px-2">
                          <label @click="uploadFiles"
                            class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md w-16 h-16 m-0">
                            <span class="m-auto text-center whitespace-nowrap">
                              <i class="el-icon-document-add text-lg" />
                              <span class="block text-xs">{{
                                $t("Thêm file")
                                }}</span>
                            </span>
                          </label>
                        </div>
                        <div class="px-2">
                          <label @click="uploadDicom"
                            class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md h-16 m-0 px-2">
                            <span class="m-auto text-center whitespace-nowrap">
                              <i class="el-icon-document-add text-lg" />
                              <span class="block text-xs">{{
                                $t("Thêm file Dicom")
                                }}</span>
                            </span>
                          </label>
                        </div>
                        <div class="px-2">
                          <label @click="onCreateANewLink"
                            class="flex relative bg-gray-50 hover:bg-gray-100 transition-all duration-200 cursor-pointer border border-solid border-gray-200 rounded-md w-16 h-16 m-0">
                            <span class="m-auto text-center whitespace-nowrap">
                              <i class="el-icon-link text-lg" />
                              <span class="block text-xs">{{
                                $t("Thêm link")
                                }}</span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <template>
                  <div class="mb-3">
                    <h4 class="fs-15">
                      <span>{{ $t("Mô tả") }}</span>
                    </h4>
                    <div class="hodo-table" v-if="!modifiable && examinationResults"
                      v-html="conclusion ? conclusion.description : ''"></div>
                    <div v-else class="hodo-ckeditor__default">
                      <TiptapEditor :value="form.description" @setValue="(t) => (form.description = t)" />
                    </div>
                  </div>
                  <div class="mb-3" v-if="type === 2 && tableData.length && hasResult">
                    <h4 class="fs-15">{{ $t("Kết quả chẩn đoán") }}</h4>
                    <div class="mb-2 whitespace-pre-wrap">
                      {{
                        tableData[0].description ||
                          tableData[0].examination_result_values.length ? tableData[0].examination_result_values[0].value :
                          ""
                      }}
                    </div>
                  </div>
                  <div class="mb-3">
                    <h4 class="fs-15">
                      <span>
                        {{
                          $t(
                            isXQuang
                              ? "Lời dặn của BS chuyên khoa:"
                              : "Kết luận"
                          )
                        }}
                      </span>
                    </h4>
                    <div v-if="!modifiable && examinationResults" v-html="conclusion ? conclusion.conclusion : ''">
                    </div>
                    <div v-else class="hodo-ckeditor__default">
                      <TiptapEditor :value="form.conclusion" @setValue="(t) => (form.conclusion = t)" />
                    </div>
                  </div>
                </template>
                <div class="mb-3">
                  <h4 class="fs-15">
                    <span>{{ $t("Nhận định kết quả") }}</span>
                  </h4>
                  <div class="hodo-table" v-if="!modifiable && examinationResults"
                    v-html="conclusion ? conclusion.indentify : ''">
                  </div>
                  <div v-else class="hodo-ckeditor__default">
                    <div class="flex items-center gap-2">
                      <div v-for="item in indentifyList" :key="item.value" class="indentify-card" :class="indentifySelected === item.value ? 'active' : ''
                        " @click="handleSelectIndentify(item)">
                        <span>{{ item.label }}</span>
                      </div>
                    </div>
                    <TiptapEditor class="mt-2" :value="form.indentify" @setValue="(t) => (form.indentify = t)" />
                  </div>
                </div>
                <div class="mb-3" v-if="indication.sign">
                  <div class="flex flex-col items-end text-center">
                    <div class="mr-4">
                      <div class="mb-2">
                        {{ getHMDMYVN(indication.sign.sign_at) }}
                      </div>
                      <div class="uppercase font-bold">
                        {{ $t("Bác sĩ chỉ định") }}
                      </div>
                      <div class="mb-2 italic">{{ $t("(Đã xem)") }}</div>
                      <div>{{ indication.signer_name }}</div>
                    </div>
                  </div>
                </div>
                <div>
                  <div class="flex items-center justify-center">
                    <button v-if="!indication.sign" @click="onSubmitFormIndication" :disabled="isLoading" type="button"
                      class="py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid bg-blue-700 hover:bg-blue-600 transition-all duration-200">
                      <span class="ml-1 text-white">{{
                        !modifiable ? $t("Cập nhật") : $t("Lưu")
                        }}</span>
                    </button>

                    <button v-if="
                      !indication.sign &&
                      modifiable &&
                      !examinationResults &&
                      isSyncPatientVisitTMatsouka
                    " type="button" @click="handleCancelTests"
                      class="py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid border-red-300 text-red-800 bg-red-50 hover:bg-red-200 transition-all duration-200">
                      <i class="el-icon-close" />
                      <span class="ml-1">{{ $t("Hủy chỉ định") }}</span>
                    </button>
                    <template v-if="modifiable && examinationResults">
                      <button @click="editForm = false" type="button"
                        class="py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid border-red-300 text-red-800 bg-red-50 hover:bg-red-200 transition-all duration-200">
                        <i class="el-icon-close" />
                        <span class="ml-1">{{ $t("Hủy bỏ") }}</span>
                      </button>
                    </template>
                    <template v-else-if="!modifiable">
                      <button v-if="!indication.sign" @click="handleOpenSignPDF" type="button"
                        class="py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid bg-pri btn transition-all duration-200">
                        <i class="el-icon-circle-check flex items-center justify-center text-white" />
                        <span class="ml-1 text-white">{{
                          $t("Ký xác nhận")
                          }}</span>
                      </button>
                      <!-- <button
                  @click="handleOpenSignPDF"
                  type="button"
                  class="py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid border-blue-300 text-blue-800 bg-blue-50 hover:bg-blue-200 transition-all duration-200"
                >
                  <i class="el-icon-circle-check" />
                  <span class="ml-1">{{ $t('Ký xác nhận') }}</span>
                      </button>-->
                      <button type="button" @click="openUrl({ fileName: indication.document_link })" :class="`py-2 px-3 ml-2 rounded-lg font-medium outline-none focus:outline-none border border-solid border-blue-300 text-blue-800 ${indication.sign
                          ? 'bg-blue-50 hover:bg-blue-200'
                          : 'opacity-50 cursor-not-allowed'
                        } transition-all duration-200`" :disabled="!indication.sign">
                        <i class="el-icon-printer" />
                        <span class="ml-1">{{ $t("In") }}</span>
                      </button>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="type === 1 && tableData.length && hasResult" class="col-7">
              <TestResultForm ref="testResultForm" :tableData="tableData" :generalInfo="generalInfo"
                :patientVisit="patientVisit" />
            </div>
          </div>
        </div>
      </div>

      <div v-if="indication">
        <TempIndicationUltrasound :show="false" :isXQuang="isXQuang" :save_pdf="save_pdf" :formData="formDataTemp"
          :tableData="tableData" />
      </div>
    </el-dialog>
    <ModalSignPDF containerIDProps="IndicationContainerPDF" elementIDProps="IndicationSignerPDF"
      imageIDProps="IndicationImageID" ref="IndicationSignerPDF" :envelopeName="envelopeName"
      @onSuccess="handleSignDocumentSuccess" />
    <PdfViewer ref="DetailIndicationPdfViewer" containerIDProps="ContainerDetailIndicationPDFViewerID"
      elementIDProps="DetailIndicationPDFViewerID" />
  </div>
</template>

<script>
import EditorInput from '@/components/EditorInput.vue'
import TiptapEditor from '@/components/Editors/Tiptap'
import TempIndicationUltrasound from '@/templates/ChiDinhSieuAm/TempIndicationUltrasound'
import appUtils from '@/utils/appUtils'
import { DOCUMENT_SIGN_TYPES, INDICATION_TYPES } from '@/utils/constants'
import mdtUtils from '@/utils/mdtUtils'
import { mixinGetPersonInfo } from '@/utils/mixinGetPersonInfo'
import uploadCircleFile from '@/utils/uploadCircleFile'
import uploadFileWithTenant from '@/utils/uploadFileWithTenant'
import uploadIndicationFile from '@/utils/uploadIndicationFile'
import uploadS3File from '@/utils/uploadS3File'
import SignatureRequest from '../../../api/request/SignatureRequest'
import {
  ENVELOPE_DOCUMENT_TYPE,
  ENVELOPE_NAME_TYPE,
  SIGNATORY_ROLE
} from '../../../utils/constants'
import { mixinSignPdf } from '../../../utils/mixinSignPdf'
import PdfViewer from '../../PdfViewer/PdfViewer.vue'
import ModalSignPDF from '../../Signature/ModalSignPDF.vue'
import TestResultForm from './TestResultFormV2.vue'

export default {
  name: 'ModalIndicationViewDetail',
  mixins: [mixinGetPersonInfo, mixinSignPdf],
  props: {
    patientVisit: {
      type: Object,
      required: true
    },
    showIndicationDetail: {
      type: Boolean,
      default: false
    },
    indicationDetailData: {
      type: Object,
      required: true
    },
    type: {
      type: Number,
      required: true
    }
  },
  components: {
    TempIndicationUltrasound,
    EditorInput,
    TiptapEditor,
    TestResultForm,
    ModalSignPDF,
    PdfViewer
  },
  data() {
    const defaultForm = {
      quantitativeIndex: '',
      description: '',
      conclusion: '',
      files: [],
      links: [],
      indentify: ''
    }
    const generalInfoInitial = {
      diagnose: '',
      sample_taker_name: '',
      take_sample_at: '',
      sample_receiver_name: '',
      received_sample_at: '',
      patient_status: 0
    }

    return {
      isLoading: false,
      indication: null,
      conclusion: null,
      personData: null,
      examinationResults: null,
      appUtils,
      mdtUtils,
      defaultForm,
      form: this.defaultForm,
      editForm: false,
      uploadIndicationFile,
      s3Urls: [],
      INDICATION_TYPES,
      save_pdf: 0,
      templateForm: null,
      isSaveAndSign: false,
      tableData: [],
      generalInfoInitial,
      generalInfo: generalInfoInitial,
      showResult: false,
      indentifyList: [
        { label: 'Bình thường', value: 1 },
        { label: 'Có ý nghĩa lâm sàng - CS', value: 2 },
        { label: 'Không có ý nghĩa lâm sàng - NCS', value: 3 },
        { label: 'Khác', value: 4 }
      ],
      indentifySelected: 0,
      isSigning: false
    }
  },
  async created() {
    await this.getIndicationById(this.indicationDetailData?.id)
    await this.handleGetIndicationResult()

    const isInvalidValueIndicationResult = this.tableData?.every(
      (item) => !item.value
    )

    if (
      this.type !== 1 ||
      this.tableData?.length <= 0 ||
      isInvalidValueIndicationResult
    ) {
      return
    }

    if (this.form?.links?.length) {
      const links = [...this.form?.links]

      const hasResultFile = links.some((link) => {
        return link?.name?.includes(`_${this.indication?.id}_`)
      })
      if (!hasResultFile && this.tableData?.length > 0) {
        await this.handlePrint()
      }
    } else {
      if (this.tableData?.length > 0) {
        await this.handlePrint()
      }
    }
  },
  mounted() {
    this.$root.$on('onSavePDFToS3', this.savePdfToS3)
  },
  destroyed() {
    this.$root.$off('onSavePDFToS3')
  },

  watch: {
    // $refresh () {
    //   if (this.form.files?.length || this.form.links?.length) {
    //     this.$emit('refresh')
    //     this.getIndicationById(this.indication.id)
    //   }
    // },
    async indicationDetailData(data) {
      if (data) {
        this.indication = data
        await this.handleGetIndicationResult(data)
      }
    },
    indication(data) {
      if (data) {
        this.form = {
          ...this.form,
          files: data.files,
          links: data.links
        }
      }
    },
    s3Urls(data) {
      if (data) {
        /* eslint-disable no-unused-expressions */
        this.form.files?.forEach((file, index) => {
          this.form.files[index] = {
            ...file,
            s3Urls: this.s3Urls?.[index]
          }
        })
      }
    },
    examinationResults(data) {
      if (data) {
        this.examinationResults = data
      }
    },
    conclusion(data) {
      if (data) {
        this.form = {
          ...this.form,
          conclusion: data.conclusion,
          description: data.description
        }
      }
    },
    templateForm(data) {
      if (data) {
        if (this.conclusion?.description?.length) return

        this.form = {
          ...this.form,
          description: data.content || ''
        }
      }
    }
  },
  computed: {
    modifiable() {
      return !this.examinationResults || !!this.editForm
    },
    formDataTemp() {
      let sign = null
      sign = {
        sign_at: window.moment(),
        signer_name: this.$user?.name,
        // uuid: this.indication?.sign?.uuid,
        isDocSign: false
      }
      return {
        person: this.personData,
        indication: this.indication,
        sign,
        type: this.type
      }
    },
    isXQuang() {
      // indication category of X-Quang is 8686
      return this.indication?.indication_category_id === 8686
    },
    hasResult() {
      return this.tableData.some((item) => item?.result_at !== null)
    },
    isSyncPatientVisitTMatsouka() {
      if (!this.$globalClinic?.settings) return false
      const settings = JSON.parse(this.$globalClinic?.settings)
      const isSyncTMatsouka = settings?.his_connect === 'tmc'
      return isSyncTMatsouka
    },
    envelopeName() {
      return this.handleGetEnvelopeName(
        ENVELOPE_NAME_TYPE.patientVisit,
        this.patientVisit
      )
    }
  },
  methods: {
    onHideModal() {
      this.$emit('hideModal', true, true)
    },
    async getIndicationById(id) {
      if (!id) return

      const self = this

      try {
        self.isLoading = true
        await self.$rf
          .getRequest('DoctorRequest')
          .getIndicationById(id)
          .then(async (res) => {
            const indicationFiles = await Promise.all(
              res.data.files?.map(async (item) => {
                if (item.type === 4) {
                  const resultUrl = await self.handleGetUrlSource(
                    item.file_name
                  )
                  return {
                    ...item,
                    resultUrl
                  }
                } else {
                  return item
                }
              })
            )

            self.indication = { ...res.data, files: indicationFiles }
            self.personData = res.data.indication_order?.person || null
            self.examinationResults = res.data.examination_results || null
            self.conclusion = res.data.examination_results?.conclusion || null
            const formatDescription = appUtils.formatImageBase64TiptapEditor(
              self.conclusion?.description
            )
            if (formatDescription) {
              self.conclusion.description = formatDescription
            }

            await this.getIndicationTempByCateId(
              self.indication?.indication_category_id
            )
          })

        const s3UrlsRaw = await Promise.all(
          self.indication?.files?.map(async (file, index) => {
            const res = await self.downloadFile({
              name: file.file_name,
              open: false
            })
            return res
          })
        )

        this.s3Urls = s3UrlsRaw
      } catch (error) {
        console.log(error)
        self.isLoading = false
      } finally {
        self.isLoading = false
      }
    },
    async getIndicationTempByCateId(indicationId) {
      if (!indicationId) return

      const self = this

      try {
        self.isLoading = true

        const res = await self.$rf
          .getRequest('DoctorRequest')
          .getIndicationTemplateByCateId(indicationId)

        if (res?.data) {
          this.templateForm = res.data
        }
      } catch (error) {
        console.log(error)
        self.isLoading = false
      }
    },
    isImage(file) {
      if (!file) return false
      if (file?.url && /(getimagefile)/.test(file?.url)) {
        return true
      }

      if (file instanceof File) {
        return !!file.type.match('image.*')
      }

      const extensions = ['jpg', 'jpeg', 'png', 'tiff', 'bmp', 'svg']
      const extVal = file?.file_name?.split('.').pop().toLowerCase()

      return extensions.includes(extVal)
    },
    getExtensionOfFile(filename) {
      if (!filename) return
      return filename?.split('.').pop().toLowerCase()
    },
    getImageURL(path) {
      return appUtils.getImageURL(path)
    },
    getLangCode() {
      return appUtils.getLangCode(appUtils.getLangId(this.$lang))
    },
    getHMDMYVN(date) {
      if (!date) return
      const string = this.moment(date).locale(this.$lang)
      if (this.$lang === this.getLangCode()) {
        const dt = this.moment(date)
        const hms = dt.clone().format('HH:mm:ss')
        const d = dt.clone().format('DD')
        const m = dt.clone().format('MM')
        const y = dt.clone().format('YYYY')
        return hms + ', ngày ' + d + ' tháng ' + m + ' năm ' + y
      } else {
        return string.format('LLL')
      }
    },
    onToggleModalUploadFileIndication(show) {
      window.$('#modalUploadFileIndication').modal(show ? 'show' : 'hide')
    },
    onSelectedFile(e) {
      const files = e.target.files
      if (files) {
        this.form.files = [...this.form.files, ...files]

        // const fileArr = Array.from(files)

        // fileArr.forEach(file => {
        //   if (self.isImage(file)) {
        //     self.encodeImageFileAsURL(file)
        //   }
        // })

        this.onSubmitFiles(files)
      }
    },
    onHandleCreateObjectURL(file) {
      if (!file) return ''
      if (file instanceof File) {
        return URL.createObjectURL(file)
      }
      return file?.s3Urls
    },
    uploadFiles() {
      this.$refs.inputFileIndication.click()
      this.$refs.inputFileIndication.value = null
    },
    onCreateANewLink() {
      this.form.links = [
        ...this.form.links,
        {
          name: '',
          url: '',
          type: 2
        }
      ]
    },
    onDeleteIndicationAttachWithType({ id, index, type }) {
      this.$confirm(
        this.$t(
          `Bạn chắc chắn muốn xóa ${type === 1 ? 'tệp' : 'liên kết'} này?`
        ),
        this.$t('Cảnh báo')
      )
        .then(async (_) => {
          await this.deleteIndicationAttach(id)
          if (type === 1) {
            this.form.files.splice(index, 1)
          } else {
            this.form.links.splice(index, 1)
          }
        })
        .catch((_) => { })
    },
    async deleteIndicationAttach(id) {
      if (!id) return
      try {
        let result = null
        await this.$rf
          .getRequest('DoctorRequest')
          .deleteIndicationAttach(id)
          .then((res) => {
            result = res
            this.$message({
              message: this.$t('Xóa tệp thành công'),
              type: 'success'
            })
          })
          .catch((error) => {
            console.log(error)
            this.$message({
              message: this.$t('Xóa tệp thất bại'),
              type: 'error'
            })
          })

        return result
      } catch (error) {
        console.log(error)
      }
    },
    async onSubmitFiles(file) {
      const self = this

      // const validFiles = this.form.files?.filter(item => {
      //   return (
      //     item?.lastModifiedDate && item?.size && !appUtils.isVideo(item?.name)
      //   )
      // })
      const validFiles = [...file]

      const videoFiles = this.form.files?.filter((item) => {
        return (
          item?.lastModifiedDate && item?.size && appUtils.isVideo(item?.name)
        )
      })

      await this.onUploadPac(videoFiles, 4)

      if (!validFiles.length) return

      const nameFiles = validFiles?.map((file) => file.name)

      self.isLoading = true
      await uploadFileWithTenant
        .fileUploadHandler({
          files: validFiles,
          path: 'resources/indications'
        })
        .then(async (res) => {
          // eslint-disable-next-line no-unused-expressions
          await Promise.all(
            res?.map(async (fileUrl, index) => {
              if (!fileUrl) return

              const params = {
                indication_id: this.indication?.id,
                type: 1,
                name:
                  nameFiles[index]?.description ||
                  validFiles[index]?.name ||
                  '',
                file_name: fileUrl
              }
              if (this.examinationResults?.id) {
                params.examination_result_id = this.examinationResults?.id
              }

              await this.$rf
                .getRequest('DoctorRequest')
                .createIndicationAttach(this.indication?.id, params)
                .then((r) => {
                  if (self.isImage(validFiles[index])) {
                    // this.encodeImageFileAsURL(fileUrl)
                  }
                  return {
                    ...r.data,
                    isUploaded: true
                  }
                })
                .catch((error) => {
                  console.log('error', error)
                })
                .finally(() => {
                  self.isLoading = false
                })
            })
          )
        })
        .finally(() => {
          self.isLoading = false
        })
    },
    async onSubmitLinks() {
      if (!this.form.links.length) return

      await Promise.all(
        this.form.links?.map(async (link) => {
          if (link.id) return
          await this.$rf
            .getRequest('DoctorRequest')
            .createIndicationAttach(this.indication?.id, link)
        })
      )
    },
    setParamsExaminationResult(params) {
      const paramsData = {
        indication_id: this.indication.id,
        ...params
      }

      delete paramsData.quantitativeIndex
      delete paramsData.files
      delete paramsData.links

      return paramsData
    },
    async createExaminationResult(param) {
      const params = this.setParamsExaminationResult(param)

      this.isLoading = true
      return this.$rf
        .getRequest('DoctorRequest')
        .createExaminationResult(params)
        .then(async (res) => {
          if (this.isSaveAndSign) {
            return res
          }

          await this.getIndicationById(this.indication.id)
          this.$emit('reloadPage')
          this.isLoading = false
          this.$message({
            type: 'success',
            message: this.$t('Lưu kết luận thành công')
          })
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.$message({
            type: 'error',
            message: this.$t('Không thể lưu kết luận')
          })
        })
    },
    async updateExaminationResult(id, param) {
      const params = this.setParamsExaminationResult(param)

      this.isLoading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updateExaminationResult(id, params)
        .then(async (res) => {
          await this.getIndicationById(this.indication.id)
          this.$emit('reloadPage')
          this.isLoading = false
          this.$message({
            type: 'success',
            message: this.$t('Chỉnh sửa kết luận thành công')
          })
        })
        .catch((error) => {
          console.log(error)
          this.isLoading = false
          this.$message({
            type: 'error',
            message: this.$t('Không thể chỉnh sửa kết luận')
          })
        })
    },
    async onSubmitFormIndication() {
      if (!this.modifiable) {
        this.editForm = true
        this.isLoading = false
        return {}
      }

      if (!this.indication.id) {
        this.$message({
          type: 'error',
          message: this.$t('Không tìm thấy thông tin của chỉ định')
        })
        this.isLoading = false

        return {}
      }

      try {
        await this.onSubmitLinks()

        let response

        if (!this.examinationResults) {
          response = await this.createExaminationResult(this.form)
        } else {
          response = await this.updateExaminationResult(
            this.examinationResults.id,
            this.form
          )
        }
        await this.getIndicationById(this.indication?.id)

        this.save_pdf++
        this.editForm = false
        return response
      } catch (error) {
        console.log(error)
      }
    },
    openLink(link) {
      link && window.open(link, '_blank', 'noreferrer')
    },
    async downloadFile({ name, open = true }) {
      if (!name) return
      const self = this
      const params = {
        fileName: name
      }
      let result = null
      const header = {
        'x-api-key': '4be5160e89e681cede9a80ae6db6af7c'
      }
      await self.$rf
        .getRequest('AuthRequest')
        .getLinkDownloadAwsRc(params, header, false)
        .then((r) => {
          if (r?.data?.data && open) {
            window.open(r.data.data)
          } else {
            result = r.data.data
          }
        })
        .catch((e) => {
          self.$toast.open({
            message: this.$t(
              'File không tồn tại hoặc gặp lỗi trong quá trình tải xuống'
            ),
            type: 'error'
          })
          console.error(e)
        })

      return result
    },
    openFile(file) {
      if (file.type === 1) {
        this.downloadFile(file.file_name)
      } else {
        this.openLink(file.url)
      }
    },
    openWindow(url) {
      this.openLink(url)
    },
    async openUrl({ fileName }) {
      if (!fileName) {
        this.$toast.open({
          message: this.$t('Không tìm thấy bản in'),
          type: 'error'
        })
        return
      }
      window.open(fileName)

      // try {
      //   this.isLoading = true
      //   const res = await this.$rf.getRequest('DoctorRequest').getEmrDocument({
      //     fileName
      //   })
      //   if (res.data) {
      //     if (print) {
      //       const printWindow = window.open(res.data?.documentLink)
      //       printWindow.print()

      //       printWindow.onafterprint = function () {
      //         printWindow.close()
      //       }
      //     } else {
      //       window.open(res.data?.documentLink)
      //     }
      //   }
      //   this.isLoading = false
      // } catch (error) {
      //   console.log(error)
      //   this.isLoading = false
      // }
    },
    async handleSignIndicationResult() {
      this.save_pdf++

      // const self = this

      // if (!self.indication.id) {
      //   self.$message({
      //     type: 'error',
      //     message: self.$t('Không tìm thấy thông tin của chỉ định')
      //   })
      //   this.isLoading = false

      //   return
      // }

      // try {
      //   self.isLoading = true

      //   const params = {
      //     id: self.indication.id,
      //     method: 1,
      //     type: DOCUMENT_SIGN_TYPES.CODE.INDICATION
      //   }

      //   const res = await self.$rf
      //     .getRequest('DoctorRequest')
      //     .signDocument(params)

      //   if (res?.data) {
      //     await this.getIndicationById(this.indication.id)
      //     await this.handleGetIndicationResult()

      //     self.isLoading = true
      //     this.save_pdf++
      //     this.$emit('reloadPage')
      //   }
      // } catch (error) {
      //   console.log(error)
      //   self.isLoading = false
      // }
    },
    async savePdfToS3(base64) {
      if (!base64 || !this.indication?.id) return
      try {
        this.isLoading = true

        const payload = {
          content: base64,
          fileName:
            `${appUtils.convertSlug(this.indication?.name)}_` || 'indication_',
          // type: 'indications'
          path: 'indications',
          type: DOCUMENT_SIGN_TYPES.CODE.INDICATION,
          type_id: this.indication?.id,
          uuid: this.indication?.identifier
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          await this.handleProcessSignEnvelope(res.data?.documentLink)

          this.isLoading = false
          this.handleOpenSignPDF()

          if (this.isSigning) {
            this.handleCheckHasEnvelop()
          }

          // if (this.isSaveAndSign) {
          //   this.$message({
          //     type: 'success',
          //     message: this.$t('Lưu phiếu và ký xác nhận thành công')
          //   })
          //   window.open(res.data?.documentLink, '_blank', 'noreferrer')
          // }
          // await this.getIndicationById(this.indication.id)
        }
      } catch (error) {
        console.log(error)
        this.isLoading = false
      } finally {
        this.isSaveAndSign = false
      }
    },
    async handleSubmitAndSign() {
      try {
        this.isLoading = true
        this.isSaveAndSign = true
        const response = await this.onSubmitFormIndication()
        if (!response) {
          this.$message({
            type: 'error',
            message: this.$t('Có lỗi xảy ra khi lưu phiếu')
          })
          this.isSaveAndSign = false
          this.isLoading = false

          return
        }

        if (response?.data?.id) {
          await this.getIndicationById(this.indication?.id)
          // this.$nextTick(() => {
          //   this.handleSignIndicationResult()
          // })
        }
      } catch (error) {
        console.log(error)
      } finally {
        // this.isLoading = false
      }
    },
    encodeImageFileAsURL(url) {
      const self = this

      const el = document.createElement('div')
      const parser = new DOMParser()
      const descriptionEl = parser.parseFromString(
        self.form.description,
        'text/xml'
      )
      el.appendChild(descriptionEl.documentElement)

      const imageString = `<img src="${url}" />`
      const imageEl = parser.parseFromString(imageString, 'text/xml')
      el.appendChild(imageEl.documentElement)

      self.form.description = el.outerHTML
    },
    uploadDicom() {
      this.$refs.inputFileIndicationDicom.click()
      this.$refs.inputFileIndicationDicom.value = null
    },
    onSelectedFileDicom(e) {
      const files = e.target.files
      if (files) {
        this.form.files = [...this.form.files, ...files]

        this.onUploadPac(files, 3)
      }
    },
    async onUploadPac(files, type) {
      if (!files?.length) return
      const newFiles = Array.from(files)

      const resultUrl = await uploadS3File.uploadHandler(
        this.$route.params.id,
        this.indication.id,
        newFiles,
        type
      )

      // let formatUrl
      // // type === 4 is video
      // if (type === 4 && resultUrl?.length) {
      //   formatUrl = await Promise.all(
      //     resultUrl.map(async (item, index) => {
      //       if (!item.url) return

      //       const url = await this.handleGetUrlSource(item.url)

      //       return { url, name: files?.[index]?.name }
      //     })
      //   )
      // } else {
      //   formatUrl = resultUrl?.map((item, index) => {
      //     return {
      //       ...item,
      //       name: files?.[index]?.name
      //     }
      //   })
      // }

      const formatUrl = resultUrl?.map((item, index) => {
        return {
          ...item,
          name: files?.[index]?.name
        }
      })

      await this.handleSubmitFilleUrl(formatUrl, type)

      this.getIndicationById(this.indication.id)
    },
    async handleSubmitFilleUrl(urlList, type) {
      const self = this
      if (!urlList?.length) return

      const nameFiles = urlList?.map((file) => file.name)

      await Promise.all(
        urlList?.map(async (urlData, index) => {
          if (!urlData.url) return

          const params = {
            indication_id: this.indication?.id,
            type: type === 3 ? 1 : type,
            name: nameFiles[index]?.name || this.form.files[index]?.name || '',
            file_name: urlData.url
          }

          if (urlData.pac_id) {
            params.pac_id = urlData.pac_id
          }
          if (this.examinationResults?.id) {
            params.examination_result_id = this.examinationResults?.id
          }

          await this.$rf
            .getRequest('DoctorRequest')
            .createIndicationAttach(this.indication?.id, params)
            .then((r) => {
              return r.data
            })
            .catch((error) => {
              console.log('error', error)
            })
            .finally(() => {
              self.isLoading = false
            })
        })
      )
    },
    handleGetPacInfo(file) {
      let data = {
        type: 'primary',
        label: 'Đang xử lý'
      }
      if (file?.pacs?.status === 1) {
        data = {
          type: 'primary',
          label: 'Đang xử lý'
        }
      } else if (file?.pacs?.status === 2) {
        data = {
          type: 'success',
          label: 'Đã xử lý'
        }
      }

      return data
    },
    async openPac(pacInfo) {
      if (!pacInfo?.id || pacInfo?.status !== 2 || !pacInfo?.accession) return

      let self = this
      let params = {
        accession: pacInfo.accession
      }
      await self.$rf
        .getRequest('DoctorRequest')
        .getUrlPac(params)
        .then((r) => {
          if (r.data) {
            window.open(r.data)
          }
        })
    },
    async handleGetUrlSource(url) {
      if (url.includes('resources/patientVisits')) {
        const resultUrl = await uploadCircleFile.getLinkVideoAWS(url)

        return resultUrl
      } else {
        return url
      }
    },
    arraySpanMethod({ row, column, rowIndex, columnIndex }) {
      if (row.is_full_row) {
        if (columnIndex === 0) {
          return [1, 6]
        } else return [0, 0]
      }
    },
    async handleGetIndicationResult(data) {
      this.tableData = []
      this.generalInfo = {
        ...this.generalInfoInitial
      }
      const id = data?.id || this.indicationDetailData?.id

      if (!id) return

      try {
        this.isLoading = true

        const params = {
          indication_ids: id
        }
        const res = await this.$rf
          .getRequest('DoctorRequest')
          .getLisIndicationsResultData(params)
        // console.log(res)

        if (res?.data) {
          res.data.Data.forEach((exam_group) => {
            this.tableData = [
              ...this.tableData,
              {
                ...exam_group,
                test_name:
                  exam_group.name ||
                  exam_group.description ||
                  this.indication?.name,
                is_full_row: Boolean(exam_group.name || exam_group.description)
              }
            ]
            const resultArr = exam_group?.examination_result_values.map(
              (exam) => {
                let lowHigh =
                  Number(exam.lower) !== Number(exam.upper)
                    ? !isNaN(Number(exam.value))
                      ? Number(exam.value) > Number(exam.upper)
                        ? 'H'
                        : Number(exam.value) < Number(exam.lower)
                          ? 'L'
                          : ''
                      : ''
                    : ''

                return {
                  test_name: exam.name,
                  result: exam.value || '-',
                  low_high: lowHigh,
                  unit: exam.unit || '-',
                  normal_rate:
                    Number(exam.lower) !== Number(exam.upper)
                      ? `${exam.lower} - ${exam.upper}`
                      : '-',
                  device: exam_group.device || '-',
                  note: exam?.note || '-',
                  is_alert: lowHigh !== ''
                }
              }
            )
            this.tableData = [...this.tableData, ...resultArr]
          })
          this.generalInfo =
            {
              ...res.data.meta,
              take_sample_at: appUtils.isDate(res.data.meta?.take_sample_at)
                ? appUtils.formatDateTimeFull(res.data.meta?.take_sample_at)
                : '',
              received_sample_at: appUtils.isDate(
                res.data.meta?.received_sample_at
              )
                ? appUtils.formatDateTimeFull(res.data.meta?.received_sample_at)
                : ''
            } || this.generalInfo
        }

        return res.data
      } catch (err) {
        console.log(err)
        this.$message({
          message: 'Lấy dữ liệu xét nghiệm không thành công',
          type: 'warning'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleToggleShowResult() {
      this.showResult = !this.showResult
    },
    async handleCancelTests() {
      try {
        let _confirm = await this.$confirm(
          this.$t('Bạn chắc chắn muốn hủy xét nghiệm này?'),
          this.$t('Cảnh báo'),
          {
            dangerouslyUseHTMLString: true
          }
        ).catch((_) => { })

        if (!_confirm) return

        const params = {
          mode: 3, // MODE: 1: Huy luot kham, 2: Complete, 3: Huy XN
          person_id: this.patientVisit?.person_id,
          patient_visit_code:
            this.patientVisit?.patient_visit_code ||
            this.patientVisit.appt?.uuid_code,
          org_id: this.$globalClinic?.org_id,
          appointment_id: this.patientVisit?.appt_id,
          indication_id: this.indication?.id
        }

        const response = await this.$rf
          .getRequest('TmatsuokaRequest')
          .completedPatientVisit(params)

        if (response.status === 200) {
          this.$emit('refresh')
          this.$emit('reloadPage')
          await this.getIndicationById(this.indication.id).then(() => {
            this.onHideModal()
          })
          return true
        }
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async savePdfToS3Indication(base64) {
      if (!base64) return

      try {
        const date = window
          .moment(this.indication?.created_at)
          .format('HHmmssDDMMYYYY')
        const payload = {
          content: base64,
          fileName: `${appUtils.convertSlug(
            `${this.$t('Kết quả xét nghiệm')}`
          )}_${this.indication?.id}_`,
          path: `xncls/${this.indication?.id}_${date || 'unknown'}`,
          type: 'print_form',
          type_id: 'print_form_id',
          uuid: ''
        }

        const res = await this.$rf
          .getRequest('DoctorRequest')
          .printDocument(payload)

        if (res?.data) {
          this.form?.links.push({
            name: `${date}_${this.indication?.id}_KQXN_${appUtils.convertSlug(
              this.indication?.name
            )}`,
            url: res.data?.documentLink,
            type: 2
          })
          // window.open(res.data?.documentLink, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handlePrint() {
      try {
        this.isLoading = true
        const tempHTML = appUtils.templateHTMLPrint({
          title: document.title,
          bodyHTML: this.$refs.testResultForm?.$el.innerHTML,
          appendStyle: true
        })
        const base64Data = appUtils.base64.decode(tempHTML)
        await this.savePdfToS3Indication(base64Data)
        await this.onSubmitLinks()
        await this.getIndicationById(this.indication?.id)
        this.$emit('reloadPage')
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async handleCheckHasEnvelop() {
      const signEnvelope = await this.handleCheckSignEnvelope(
        ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT,
        this.indication?.id,
        'DetailIndicationPdfViewer',
        'IndicationSignerPDF'
      )
      this.isSigning = false

      return signEnvelope
    },
    async handleOpenSignPDF() {
      const signEnvelope = await this.handleCheckHasEnvelop()

      if (!signEnvelope?.id) {
        this.save_pdf++
        this.isSigning = true
      }
    },
    async getSignProcedure() {
      try {
        const params = {
          page_num: 1,
          page_size: 1000,
          order: 'desc',
          sort_by: 'id',
          document_type: ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT,
          ws_id: this.$globalClinic?.id
        }

        const request = new SignatureRequest()

        const response = await request.getSignProcedures(params)
        return response.data
      } catch (error) {
        console.log(error)
      }
    },
    async uploadFilesToUrl(file, id) {
      const params = {
        file,
        FolderTarget: `indication_result`,
        IdTarget: id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async handleSignDocumentSuccess(database64) {
      try {
        this.isLoading = true
        const self = this
        const file = appUtils.convertBase64tToFile(
          database64,
          parseInt(Date.now() / 1000)
        )
        const url = await this.uploadFilesToUrl(file, this.indication?.id)

        await this.$rf
          .getRequest('DoctorRequest')
          .updateIndicationV2(this.indication.id, { document_link: url })
        const params = {
          id: self.indication.id,
          method: 1,
          type: DOCUMENT_SIGN_TYPES.CODE.INDICATION
        }

        const res = await self.$rf
          .getRequest('DoctorRequest')
          .signDocument(params)

        if (res?.data) {
          await this.getIndicationById(this.indication.id)
          await this.handleGetIndicationResult()

          this.$emit('reloadPage')

          window.open(this.indication.document_link, '_blank', 'noreferrer')
        }
      } catch (error) {
        console.log(error)
        this.isLoading = false
      } finally {
        this.isLoading = false
      }
    },
    handleSelectIndentify(item) {
      this.indentifySelected = item.value
      if (item.value !== 4) this.form.indentify = item.label
      else {
        this.form.indentify = ''
      }
    },
    async handleProcessSignEnvelope(documentUrl) {
      let responseData
      const procedureSelected = await this.getSignProcedures(
        ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT
      )
      const signatories = procedureSelected?.sign_nodes?.reduce((arr, node) => {
        const signs = node?.node_signatories?.map((item) => {
          let user
          if (item?.user_role === 'doctor_exam') {
            user = this.$user
          }

          return {
            ...item,
            user,
            user_id: user?.id,
            node_signatory_id: item?.id,
            signatory_name: user?.name,
            signatory_email: user?.email,
            signatory_role: SIGNATORY_ROLE.signer,
            signatory_order: item?.sign_order,
            role_name: item?.role_name,
            is_required: item?.is_required,
            expired_date: item?.expired_date || undefined
          }
        })

        return arr.concat(signs)
      }, [])

      const signEnvelope = await this.handleGetEnvelopeByDocumentID(
        this.indication?.id,
        ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT
      )

      if (signEnvelope?.id) {
        responseData = await this.handleUpdateSignEnvelope(
          signEnvelope.id,
          signatories,
          documentUrl,
          ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT,
          this.indication?.id,
          this.envelopeName
        )

        return responseData
      } else {
        responseData = await this.handleCreateEnvelope(
          signatories,
          documentUrl,
          procedureSelected?.id,
          ENVELOPE_DOCUMENT_TYPE.INDICATION_RESULT,
          this.indication?.id,
          this.envelopeName
        )

        return responseData
      }
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-table {
  margin-top: 10px;
  border-top: 1px solid #000 !important;

  &__header-wrapper {
    text-transform: uppercase;

    th {
      padding: 8px 0px;
      background-color: #f0f0f0 !important;
    }
  }

  &__row {
    // .el-table__cell:last-of-type {
    //   border-right: none !important;
    // }
  }

  &__cell {
    color: black;
    border-right: 1px solid #000 !important;
    border-bottom: 1px solid #000 !important;
  }

  &--border {
    border-bottom: 1px solid #000 !important;
  }
}

::v-deep {
  .hodo-ckeditor {
    &__default {
      .ck-editor__editable {
        min-height: 70px;
      }
    }

    &__big {
      .ck-editor__editable {
        min-height: 350px;
      }
    }
  }

  .hodo-table {
    img {
      max-width: 800px;
      object-fit: cover;
      object-position: center;
    }
  }

  .hodo-table table {
    width: 100% !important;
    border: 1px solid #dbdde0 !important;
    margin-top: 1rem !important;
  }

  .hodo-table table thead th {
    text-transform: uppercase !important;
  }

  .hodo-table table th,
  .hodo-table table td {
    padding: 8px !important;
    border: 1px solid #dbdde0 !important;
  }
}

.indentify-card {
  cursor: pointer;
  padding: 4px 8px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  width: fit-content;
  transition: all linear 0.2s;
}

.active {
  background: #20419b;
  color: #fff;
}

.selected-file-icon {
  width: 100%;
  height: 100%;
  background-size: contain;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center;
}
</style>
